import React, { useEffect } from 'react'
import breadcrum from '../img/breadcrumb-bg.jpg'
import eyevedioimg from '../img/eyevediosimage.jpg'
import laafilogo from '../img/logo-icon.png'
import certificateicon from '../img/icons/certificate-icon.svg'
import doctoricon from '../img/icons/doctor-icon.svg'
import equpmenticon from '../img/icons/equipment-icon.svg'
import { Button, IconButton } from '@mui/material'
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const About = ({content,setcontent}) => {

  return (
    <div>
        {/* Breadcrumb Section Begin  */}
    <section className="breadcrumb-option spad set-bg"  style={{backgroundImage:`url(${breadcrum})`}}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <div className="breadcrumb__text">
                        <h2>About Us</h2>
                        <div className="breadcrumb__links">
                            <a onClick={()=>{setcontent('home')}}>Home</a>
                            <span>About</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/*  Breadcrumb Section End */}

    {/* <!-- About Section Begin --> */}
    <section className="about spad">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="about__video set-bg" style={{backgroundImage:`url(${eyevedioimg})`}}>
                        <a href="https://www.youtube.com/watch?v=PXsuI67s2AA" className="play-btn video-popup"><i className="fa fa-play"></i></a>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="about__text">
                        <div className="section-title">
                            <span>ABOUT OUR Hospital</span>
                            <h2>Welcome to the Kruthika</h2>
                        </div>
                        <p>At Kruthika, we are dedicated to enhancing and preserving your vision through exceptional care, advanced technology, and personalized treatment. Established with a mission to provide world-class eye care services, our hospital stands as a beacon of hope and excellence in the field of ophthalmology.</p>
                        <ul>
                            <li><i className="fa fa-check-circle"></i> Personalized Care</li>
                            <li><i className="fa fa-check-circle"></i> Comprehensive Services</li>
                            <li><i className="fa fa-check-circle"></i> Patient-Centered Environment</li>
                        </ul>
                        <a className="primary-btn normal-btn" onClick={()=>{setcontent('contact')}}>Contact us</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/*About Section End */}
        <section className="chooseus spad">
        <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={doctoricon} alt=""/>
              <h5>Qualified Doctors</h5>
              <p>Qualified eye doctor combines extensive medical training with years of hands-on experience to deliver exceptional eye care.</p>
              <Button variant='contained' className='mt-5'> book now</Button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={equpmenticon} alt=""/>
               <h5>Advanced equipment</h5>
              <p>Our eye clinic is equipped with the latest advanced technology, including high-resolution retinal imaging and automated visual field tests, ensuring precise diagnoses and effective treatment plans.</p>
              <Button variant='contained' className='mt-5' > book now</Button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={certificateicon} alt=""/>
              <h5>Certified Services</h5>
              <p>Our eye clinic offers certified services ensuring the highest quality of care for your vision needs.</p>
              <Button variant='contained' className='mt-5'  > book now</Button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
               <img src={laafilogo} alt="" className='laafi_logo'/>
              <h5>Laafi</h5>
              <p>Laafi is used to schedule appointments, help patients and doctors keep track of health records and more</p>
              <Button variant='contained' className='mt-5' > book now</Button>
            </div>
          </div>
        </div>
        </div>
    </section>
     {/* <!-- Testimonials Section Begin --> */}
    <section className="testimonials spad">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <div className="section-title">
                        <span>Testimonials</span>
                        <h2>Happy clients</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="testimonial__slider owl-carousel">
                    <div className="col-lg-6">
                        <div className="testimonial__item">
                            <div className="testimonial__author">
                                <div className="testimonial__author__icon">
                                    <i className="fa fa-quote-left"></i>
                                </div>
                                <div className="testimonial__author__text">
                                    <h5>Rajesh Kumar</h5>
                                    <span>Businessman</span>
                                </div>
                            </div>
                            <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <p>I had a fantastic experience at Kruthika Eye Hospital. From the moment I walked in, the staff was welcoming and professional. Dr. Nikitha's expertise and thoroughness made me feel confident about my treatment. </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial__item">
                            <div className="testimonial__author">
                                <div className="testimonial__author__icon">
                                    <i className="fa fa-quote-left"></i>
                                </div>
                                <div className="testimonial__author__text">
                                    <h5>Priya Singh</h5>
                                    <span>House wife</span>
                                </div>
                            </div>
                            <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <p>Kruthika Eye Hospital is truly exceptional. The facilities are state-of-the-art, and the entire team is dedicated to providing the best care.   </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial__item">
                            <div className="testimonial__author">
                                <div className="testimonial__author__icon">
                                    <i className="fa fa-quote-left"></i>
                                </div>
                                <div className="testimonial__author__text">
                                    <h5>Amit Verma</h5>
                                    <span>Businesswoman</span>
                                </div>
                            </div>
                            <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <p>I was referred to Kruthika Eye Hospital for a specialized eye procedure, and I am thoroughly impressed.</p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial__item">
                            <div className="testimonial__author">
                                <div className="testimonial__author__icon">
                                    <i className="fa fa-quote-left"></i>
                                </div>
                                <div className="testimonial__author__text">
                                    <h5> Neha Gupta</h5>
                                    <span>IT</span>
                                </div>
                            </div>
                            <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <p>Kruthika Eye Hospital provided me with an outstanding experience. The entire process, from consultation to post-treatment care, was handled with utmost professionalism.  </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial__item">
                            <div className="testimonial__author">
                                <div className="testimonial__author__icon">
                                    <i className="fa fa-quote-left"></i>
                                </div>
                                <div className="testimonial__author__text">
                                    <h5>Sanjay Reddy</h5>
                                    <span>Businessman</span>
                                </div>
                            </div>
                            <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <p>I highly recommend Kruthika Eye Hospital for anyone needing eye care. The team's dedication and the high-quality care provided were impressive.  </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial__item">
                            <div className="testimonial__author">
                                <div className="testimonial__author__icon">
                                    <i className="fa fa-quote-left"></i>
                                </div>
                                <div className="testimonial__author__text">
                                    <h5>Anjali Desai</h5>
                                    <span>Businesswoman</span>
                                </div>
                            </div>
                            <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <p>My experience at Kruthika Eye Hospital was wonderful. The staff was incredibly friendly and made me feel comfortable throughout my visit. </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class='row d-flex justify-content-center mt-3'>
                <IconButton>
                    <IoIosArrowBack/>
                </IconButton>
                <IconButton>
                    <IoIosArrowForward/>
                </IconButton>
            </div>    */}
        </div>
    </section>
    {/* Testimonials Section End */}

    </div>
  )
}

export default About

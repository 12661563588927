import React, { useState } from 'react';
import { faFacebookF, faTwitter, faInstagram, faDribbble } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMapMarker, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import krithikalogo from '../img/krithikalogo.png';

const Header = ({ content, setcontent }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      {/* Offcanvas Menu Begin */}
      <div className={`offcanvas-menu-wrapper ${isMenuOpen ? 'active' : ''}`}>
        <div className="offcanvas__logo">
          <a href="./index.html"><img src={krithikalogo} alt="Logo" /></a>
        </div>
        <div id="mobile-menu-wrap"></div>
        <div className="offcanvas__btn">
          <a className="primary-btn">Appointment</a>
        </div>
        <ul className="offcanvas__widget">
          <li><FontAwesomeIcon icon={faPhone} /> 1-677-124-44227</li>
          <li><FontAwesomeIcon icon={faMapMarker} /> Bangalore</li>
          <li><FontAwesomeIcon icon={faClock} /> Mon to Sat 9:00am to 18:00pm</li>
        </ul>
        <ul className="offcanvas__menu">
          <li className={content === 'home' ? 'active' : ''} onClick={() => { setcontent('home'); toggleMenu(); }}>
            <a><b>Home</b></a>
          </li>
          <li className={content === 'about' ? 'active' : ''} onClick={() => { setcontent('about'); toggleMenu(); }}>
            <a><b>About</b></a>
          </li>
          <li className={content === 'contact' ? 'active' : ''} onClick={() => { setcontent('contact'); toggleMenu(); }}>
            <a><b>Contact</b></a>
          </li>
        </ul>
      </div>
      {/* Offcanvas Menu End */}

      {/* Header Section Begin */}
      <header className="header">
        <div className="header__top">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <ul className="header__top__left">
                  <li><FontAwesomeIcon icon={faPhone} /><b>+91 9886031836</b></li>
                  <li><FontAwesomeIcon icon={faMapMarker} /> <b>Bangalore</b></li>
                  <li><FontAwesomeIcon icon={faClock} /><b>Mon to Sat 9:00am to 18:00pm</b></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <div className="header__logo mt-2">
                <img src={krithikalogo} style={{ height: 'auto', width: '200px' }} alt="Krithika Logo" />
              </div>
            </div>
            <div className="col-lg-10">
              <div className="header__menu__option">
                <nav className="header__menu">
                  <ul className={isMenuOpen ? 'active' : ''}>
                    <li className={content === 'home' ? 'active' : ''} onClick={() => setcontent('home')}><a><b>Home</b></a></li>
                    <li className={content === 'about' ? 'active' : ''} onClick={() => setcontent('about')}><a><b>About</b></a></li>
                    <li className={content === 'contact' ? 'active' : ''} onClick={() => setcontent('contact')}><a><b>Contact</b></a></li>
                  </ul>
                </nav>
                <div className="header__btn">
                  <a className="primary-btn" href='http://lafi.netsrishti.net/'><b>Appointment</b></a>
                </div>
              </div>
            </div>
          </div>
          {/* Hamburger Icon */}
          <div className="canvas__open" onClick={toggleMenu}>
            <i className={`fa ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;

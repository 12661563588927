import { Provider, useSelector } from 'react-redux';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import LandingPage from './LandingPageCss/LandingPage';
import Landingpage from './LandingPageCss/Components/Landingpage'

function App() {
  return (
    <div>
        <Landingpage/>
         {/* <AdminMain/> */}
    </div>
  );
}

export default App;

import React, { useEffect } from 'react'
import ScrollReveal from 'scrollreveal';
import './css/style.css'
import { Container } from './StyledCss';
import eyeimage from '../LandingPageCss/img/eyeimage1.jpg'
import logo from '../LandingPageCss/img/logo.png'
import team1 from '../LandingPageCss/img/team/team-1.jpg'
import team2 from '../LandingPageCss/img/team/team-2.jpg'
import team3 from '../LandingPageCss/img/team/team-3.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import netsrishtilogo from '../LandingPageCss/img/watermarkCompanylogo.jpeg'
import { faFacebookF, faTwitter, faInstagram, faDribbble } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMapMarker, faClock } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';
import servicebg from './img/services-bg.jpg'
import laafilogo from './img/logo-icon.png'
import certificateicon from './img/icons/certificate-icon.svg'
import doctoricon from './img/icons/doctor-icon.svg'
import equpmenticon from './img/icons/equipment-icon.svg'
import { Button } from '@mui/material';


const LandingPage = () => {
    useEffect(()=>{
        ScrollReveal({ reset: true, distance: '60px', duration: 2500, delay: 400 })
        ScrollReveal().reveal('.hero__text', { delay: 500 });
        ScrollReveal().reveal('.section-title', { delay: 200, origin: 'top' });
        ScrollReveal().reveal('.team__item', { delay: 500, origin: 'bottom' });
        ScrollReveal().reveal('.chooseus__item', { delay: 500, origin: 'bottom' });
        ScrollReveal().reveal('.services__item', { delay: 500, origin: 'left' });
        ScrollReveal().reveal('.services__itemedit', { delay: 500, origin: 'right' });
        ScrollReveal().reveal('.latest__item', { delay: 500, origin: 'left' });
        ScrollReveal().reveal('.latest__item2', { delay: 500, origin: 'bottom' });
        ScrollReveal().reveal('.latest__item3', { delay: 500, origin: 'right' });
    },[])

    const teamimges=[team1,team2,team3]
  return (
    <Container>
    {/* Page Preloader */}
    {/* <div id="preloder">
      <div className="loader"></div>
    </div> */}

    {/* Offcanvas Menu Begin */}
    <div className="offcanvas-menu-overlay"></div>
    <div className="offcanvas-menu-wrapper">
      <div className="offcanvas__logo">
        <a href="./index.html"><img src={logo} alt="Logo" /></a>
      </div>
      <div id="mobile-menu-wrap"></div>
      <div className="offcanvas__btn">
        <button type="button" className="primary-btn">Appointment</button>
      </div>
      <ul className="offcanvas__widget">
        <li><FontAwesomeIcon icon={faPhone} /> 1-677-124-44227</li>
        <li><FontAwesomeIcon icon={faMapMarker} /> Bangalore</li>
        <li><FontAwesomeIcon icon={faClock} />Mon to Sat 9:00am to 18:00pm</li>
      </ul>
      <div className="offcanvas__social">
        <a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
        <a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
        <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
        <a href="#"><FontAwesomeIcon icon={faDribbble}/></a>
      </div>
    </div>
    {/* Offcanvas Menu End */}

    {/* Header Section Begin */}
    <header className="header">
      <div className="header__top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="header__top__left">
                <li><FontAwesomeIcon icon={faPhone}/><b>1-677-124-44227</b></li>
                <li><FontAwesomeIcon icon={faMapMarker} /> <b>Bangalore</b></li>
                <li><FontAwesomeIcon icon={faClock} /><b>Mon to Sat 9:00am to 18:00pm</b></li>
              </ul>
            </div>
            <div className="col-lg-4">
              <div className="header__top__right">
              <a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
              <a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
              <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
              <a href="#"><FontAwesomeIcon icon={faDribbble}/></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="header__logo">
              {/* <a href="./index.html"><img src="img/logo.png" alt="Logo" /></a> */}
            </div>
          </div>
          <div className="col-lg-10">
            <div className="header__menu__option">
              <nav className="header__menu">
                <ul>
                  <li className="active"><a href="#"><b>Home</b></a></li>
                  <li><a href="#"><b>About</b></a></li>
                  <li><a href="#"><b>Services</b></a></li>
                  <li><a href="#"><b>Pages</b></a>
                    <ul className="dropdown">
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Doctor</a></li>
                      <li><a href="#">Blog Details</a></li>
                    </ul>
                  </li>
                  <li><a href="#"><b>News</b></a></li>
                  <li><a href="#"><b>Contact</b></a></li>
                </ul>
              </nav>
              <div className="header__btn">
              <a  className="primary-btn"><b>Appointment</b></a>
              </div>
            </div>
          </div>
        </div>
        <div className="canvas__open">
          <i className="fa fa-bars"></i>
        </div>
      </div>
    </header>
    {/* Header Section End */}

    {/* Hero Section Begin */}
    <section className="hero spad set-bg" style={{backgroundImage:`url(${eyeimage})`}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="hero__text">
              {/* <span>Eiusmod tempor incididunt</span> */}
              <h2>See Clearly, Live Fully.</h2>
              <a href="#" className="primary-btn normal-btn">Contact us</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Hero Section End */}

    {/* Consultation Section Begin */}
    <section className="consultation">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="consultation__form">
              <div className="section-title">
                <span>REQUEST FOR YOUR</span>
                <h2>Consultation</h2>
              </div>
              <form action="#">
                <input type="text" placeholder="Name" />
                <input type="text" placeholder="Email" />
                <div className="datepicker__item">
                  <input type="text" placeholder="Date" className="datepicker" />
                  <i className="fa fa-calendar"></i>
                </div>
                {/* <select>
                  <option value="">Type of service</option>
                  <option value="">Advanced equipment</option>
                  <option value="">Qualified doctors</option>
                  <option value="">Certified services</option>
                  <option value="">Emergency care</option>
                </select> */}
                <button type="submit" className="site-btn">Book appointment</button>
              </form>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="consultation__text">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="consultation__text__item">
                    <div className="section-title">
                      <span>Welcome to Aesthetic</span>
                      <h2 style={{width:'626px'}}>Find Best Doctors With <b style={{paddingLeft:'20px'}}> Kruthika</b></h2>
                    </div>
                    <p>At the heart of the community, Kruthika Eye Hospital offers a blend of cutting-edge technology and compassionate care to ensure optimal eye health for all patients.</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="consultation__video set-bg" data-setbg="">
                    <a href="https://www.youtube.com/watch?v=PXsuI67s2AA" className="play-btn video-popup"><i className="fa fa-play d-flex justify-content-center"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Consultation Section End */}

    {/* Choose Us Section Begin */}
    <section className="chooseus spad" >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="section-title">
              <span>Why choose us?</span>
              <h2>Offer for you</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={doctoricon} alt=""/>
              <h5>Qualified Doctors</h5>
              <p>Qualified eye doctor combines extensive medical training with years of hands-on experience to deliver exceptional eye care.</p>
              <Button variant='contained' className='mt-5'> book now</Button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={equpmenticon} alt=""/>
               <h5>Advanced equipment</h5>
              <p>Our eye clinic is equipped with the latest advanced technology, including high-resolution retinal imaging and automated visual field tests, ensuring precise diagnoses and effective treatment plans.</p>
              <Button variant='contained' className='mt-5' > book now</Button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={certificateicon} alt=""/>
              <h5>Certified Services</h5>
              <p>Our eye clinic offers certified services ensuring the highest quality of care for your vision needs.</p>
              <Button variant='contained' className='mt-5'  > book now</Button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
               <img src={laafilogo} alt="" className='laafi_logo'/>
              <h5>Laafi</h5>
              <p>Laafi is used to schedule appointments, help patients and doctors keep track of health records and more</p>
              <Button variant='contained' className='mt-5' > book now</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Choose Us Section End */}

    {/* Services Section Begin */}
    <section className="services spad"style={{backgroundImage:`url(${servicebg})`}}>
      <div className="container">
      <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-6">
                    <div class="section-title">
                        <span>Our services</span>
                        <h2 style={{display:'flex', justifyContent:'flex-start'}}>Offer for you</h2>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="services__btn">
                        <a href="#" class="primary-btn">Contact us</a>
                    </div>
                </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="services__item">
              <div className="services__item__icon">
                <i className="fa fa-eye"></i>
              </div>
              <div class="services__item__text">
                <h5>Eye Surgery</h5>
                <p>Our eye clinic provides expert eye surgery with advanced techniques and personalized care.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="services__itemedit">
              <div className="services__item__icon">
                <i className="fa fa-glasses"></i>
              </div>
              <div class="services__item__text">
                <h5>Vision Correction</h5>
                <p>Our eye clinic specializes in tailored vision correction solutions to improve your sight.</p>
               </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="services__item">
              <div className="services__item__icon">
                <i className="fa fa-eye-dropper"></i>
              </div>
              <div class="services__item__text">
                <h5>Eye Care</h5>
                <p>We offer comprehensive eye care to ensure your vision stays clear and healthy.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Services Section End */}
    <section class="team spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <div class="section-title">
                        <span>Our Team</span>
                        <h2>Our Expert Doctors</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                {teamimges.map(item=>(
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="team__item">
                        <img src={item} alt=""/>
                        <h5>Dr Nikhitha</h5>
                        <span>Eye surgeon</span>
                        <div class="team__item__social">
                            <a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
                            <a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
                            <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
                            <a href="#"><FontAwesomeIcon icon={faDribbble}/></a>
                        </div>
                    </div>
                </div>
                ))}
                {/* <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="team__item">
                        <img src="img/team/team-2.jpg" alt=""/>
                        <h5>Dr. Maria Angel</h5>
                        <span>Plastic surgeon</span>
                        <div class="team__item__social">
                            <a href="#"><i class="fa fa-facebook"></i></a>
                            <a href="#"><i class="fa fa-twitter"></i></a>
                            <a href="#"><i class="fa fa-instagram"></i></a>
                            <a href="#"><i class="fa fa-dribbble"></i></a>
                        </div>
                    </div>
                </div> */}
                {/* <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="team__item">
                        <img src="img/team/team-3.jpg" alt=""/>
                        <h5>Nathan Mullins</h5>
                        <span>Plastic surgeon</span>
                        <div class="team__item__social">
                            <a href="#"><i class="fa fa-facebook"></i></a>
                            <a href="#"><i class="fa fa-twitter"></i></a>
                            <a href="#"><i class="fa fa-instagram"></i></a>
                            <a href="#"><i class="fa fa-dribbble"></i></a>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </section>

    {/* Latest News Section Begin */}
    {/* <section className="latest spad">
      <div className="container">
        <div className="section-title">
          <span>Our Latest News</span>
          <h2>Read Our Blog</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
          <div class="latest__item">
                        <h5><a href="#">Here’s how you can make your eyes clean </a></h5>
                        <p>Lorem ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        <ul>
                            <li><img src="img/blog/blog-author.jpg" alt=""/> John Doe</li>
                            <li>Dec 06, 2019</li>
                        </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
          <div class="latest__item2">
                        <h5><a href="#">Get better eyes with these top 10 tips for eye care</a></h5>
                        <p>Lorem ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        <ul>
                            <li><img src="img/blog/blog-author.jpg" alt=""/> John Doe</li>
                            <li>Dec 06, 2019</li>
                        </ul>
                    </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
          <div class="latest__item3">
                        <h5><a href="#">8 Ways to Protect your eyes </a></h5>
                        <p>Lorem ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        <ul>
                            <li><img src="img/blog/blog-author.jpg" alt=""/> John Doe</li>
                            <li>Dec 06, 2019</li>
                        </ul>
                    </div>
          </div>
        </div>
      </div>
    </section> */}
    {/* Latest News Section End */}

    {/* Footer Section Begin */}
    <footer class="footer">
        <div class="footer__top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="footer__logo">
                            <a href="#"><img src="img/footer-logo.png" alt=""/></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8">
                        <div class="footer__newslatter">
                            <form action="#">
                                <input type="text" placeholder="Email"/>
                                <button type="submit" class="site-btn">Subscribe</button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="footer__social">
                            <a href="#"><FontAwesomeIcon icon={faFacebookF} style={{display:'flex',paddingLeft:'19px'}}/></a>
                            <a href="#"><FontAwesomeIcon icon={faTwitter} style={{display:'flex',paddingLeft:'19px'}}/></a>
                            <a href="#"><FontAwesomeIcon icon={faInstagram} style={{display:'flex',paddingLeft:'19px'}}/></a>
                            <a href="#"><FontAwesomeIcon icon={faDribbble} style={{display:'flex',paddingLeft:'19px'}}/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-6">
                    <div class="footer__widget">
                        <h5>Company</h5>
                        <ul>
                            <li className='d-flex'><a href="#">About Us</a></li>
                            <li className='d-flex'><a href="#">Departments</a></li>
                            <li className='d-flex'><a href="#">Find a Doctor</a></li>
                            <li className='d-flex'><a href="#">FAQ</a></li>
                            <li className='d-flex'><a href="#">News</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6">
                    <div class="footer__widget">
                        <h5>Quick links</h5>
                        <ul>
                            <li className='d-flex'><a href="#">Facial Fillers</a></li>
                            <li className='d-flex'><a href="#">Breast Surgery</a></li>
                            <li className='d-flex'><a href="#">Body Lifts</a></li>
                            <li className='d-flex'><a href="#">Face & Neck</a></li>
                            <li className='d-flex'><a href="#">Fat Reduction</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="footer__address">
                        <h5>Contact Us</h5>
                        <ul>
                            <li className='d-flex'><i class="fa fa-map-marker"></i> Bangalore</li>
                            <li className='d-flex'><i class="fa fa-phone"></i> 1-677-124-44227</li>
                            <li className='d-flex'><i class="fa fa-envelope"></i> Support@gmail.com</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-6">
                    <div class="footer__map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48158.305462977965!2d-74.13283844036356!3d41.02757295168286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2e440473470d7%3A0xcaf503ca2ee57958!2sSaddle%20River%2C%20NJ%2007458%2C%20USA!5e0!3m2!1sen!2sbd!4v1575917275626!5m2!1sen!2sbd"
                        height="190"
                        style={{ border: 0 }}
                        allowFullScreen
                    >
                    </iframe>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__copyright">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <img src={netsrishtilogo} alt='' style={{height:'28px'}}/>
                    </div>
                    <div class="col-lg-4">
                        <p class="text-center text-md-end text-xl-start"> 
                        All Rights Reserved
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <ul>
                            <li>All Rights Reserved</li>
                            <li>Terms & Use</li>
                            <li>Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    {/* Footer Section End */}
  </Container>
  )
}

export default LandingPage
